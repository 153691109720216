import { useEffect, useState } from "react";
import { PageChangerProps } from "../../navigation/pagination/page-changer";

export type ListPageChangerProps = Partial<Omit<PageChangerProps, "total" | "setPage">> & {
	onPageChange?: (page: number) => void;
};

export function useListPageChanger<T>(
	items: T[],
	pageChanger?: Partial<Omit<PageChangerProps, "total" | "setPage">> & {
		onPageChange?: (page: number) => void;
	}
): {
	page: number;
	pageChangeHandler: (page: number) => void;
	pageSize: number;
	paginatedItems: T[];
} {
	const [page, setPage] = useState(pageChanger?.page ?? 1);

	const pageChangeHandler = (page: number) => {
		pageChanger?.onPageChange?.(page);
		setPage(page);
	};
	useEffect(() => {
		pageChangeHandler(pageChanger?.page ?? 1);
	}, [pageChanger?.page]);

	const pageSize = pageChanger?.pageSize ?? 5;
	const paginatedItems = !pageChanger ? items : items.slice((page - 1) * pageSize, page * pageSize);

	return { page, pageChangeHandler, pageSize, paginatedItems };
}

export function useListPageChangeSelection<T extends { selected?: boolean; index: number }>(
	items: T[],
	pageChangeHandler: (page: number) => void,
	pageSize: number
): {
	selected: number;
	setSelected: (index: number) => void;
} {
	const findSelected = () => items.find((item) => "selected" in item && item.selected)?.index ?? -1;
	const [selected, setSelected] = useState(findSelected());

	useEffect(() => {
		setSelected(findSelected());
	}, [items]);

	useEffect(() => {
		selected > -1 && pageChangeHandler(Math.ceil((selected + 1) / pageSize));
	}, [selected]);

	return { selected, setSelected };
}
