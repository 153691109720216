import React, { useState } from "react";
import {
	Badge,
	Button,
	Icon,
	IconContrast,
	IconFullscreen,
	IconOpenNew,
	IconSettings,
	InlineText,
	LinkContainer,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	Code,
	Header,
	ImportExample,
	Example,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { BigSmall } from "../../text/big-small/big-small";
import { ActionList, LinkItem } from "./action-list";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Action list",
	notepad: "",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Action list"
			subTitle="List-content of the ActionMenu, that can be used independently."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ActionList} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The inner list content of the <Code>ActionMenu</Code> component.
				</Paragraph>
				<Header.H3>Basic usage</Header.H3>
				<Example fn={BasicUsage} />
				<Header.H3>Searchable</Header.H3>
				<Paragraph>
					The search field allows the user to type in a keyword to search for an item, and it should
					only be used if the user is very familiar with the menu item. This way the user can access
					it quickly.
				</Paragraph>
				<Example fn={SearchableUsage} />
				<Header.H3>Custom item</Header.H3>
				<Paragraph>
					If you want to render a list item differently than the provided <Code>ActionItem</Code>,{" "}
					<Code>LinkItem</Code>, <Code>Divider</Code> and <Code>SectionHeader</Code>, you can use
					the <Code>render</Code> function.
				</Paragraph>
				<Paragraph>
					The function passes the same <Code>SharedProps</Code> that the <Code>item</Code>{" "}
					possesses, with the notable difference that the <Code>text</Code> prop has type{" "}
					<Code>JSX.Element</Code>, since it return a <Code>TextHighlight</Code> element.
				</Paragraph>
				<Paragraph>
					By passing and using such props as <Code>text</Code> within your custom item, the search
					field can search within your custom items as well.
				</Paragraph>
				<Example fn={CustomItemUsage} />
				<Header.H3>Custom renderer</Header.H3>
				<Paragraph>
					If you want to render all list items differently, you can pass the{" "}
					<Code>itemRenderer</Code> function.
				</Paragraph>
				<Paragraph>
					The function passes the props from <Code>ListOptionItem</Code>, which is{" "}
					<Code>ActionItem | LinkItem | CustomItem</Code>
				</Paragraph>
				<Example fn={CustomRendererUsage} />
				<Header.H3>Selected</Header.H3>
				<Paragraph>
					You can mark an item as selected by setting the <Code>selected</Code> prop.
				</Paragraph>
				<Example fn={SelectedUsage} />
				<Header.H3>Simple Pagination</Header.H3>
				<Example fn={SimplePaginationUsage} />
				<Header.H3>Selected item in pagination</Header.H3>
				<Paragraph>
					List will automatically paginate to a <Code>selected</Code> item.
				</Paragraph>
				<Example fn={SelectedPaginationUsage} />
				<Header.H3>Updating paginated items</Header.H3>
				<Paragraph>
					Even if the items are updated, the list will still paginate to the selected item.
				</Paragraph>
				<Example fn={UpdateItemsPaginationUsage} />
				<Header.H3>External control of pagination</Header.H3>
				<Paragraph>The pagination can be controlled from outside the component as well.</Paragraph>
				<Example fn={ExternalControlPaginationUsage} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={["...", "..."]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<ActionList
		items={[
			{ text: "Actionbutton", onClick: () => console.log("clicked") },
			{ text: "ActionLink", href: "https://www.google.com", openNew: true },
			ActionList.divider,
			{
				text: "Disabled actionbutton",
				onClick: () => console.log("clicked"),
				disabled: true,
			},
			ActionList.sectionHeader("New section"),
			{
				text: "Actionbutton",
				onClick: () => console.log("clicked"),
			},
		]}
	/>
);

const SearchableUsage = () => (
	<ActionList
		searchable
		items={[
			// without icons
			ActionList.sectionHeader("File"),
			{ text: "New File", onClick: () => console.log("clicked") },
			{ text: "Open File", onClick: () => console.log("clicked") },
			// with icons
			ActionList.sectionHeader("Settings", <IconSettings />),
			{
				text: "Increase Contrast",
				icon: <IconContrast />,
				onClick: () => console.log("clicked"),
			},
			{
				text: "Toggle Fullscreen",
				description: "this is a description",
				icon: <IconFullscreen />,
				onClick: () => console.log("clicked"),
			},
		]}
	/>
);

const CustomItemUsage = () => (
	<ActionList
		searchable
		items={[
			{
				text: "My custom button",
				description: "This is a custom button",
				onClick: () => console.log("clicked"),
				render: (props) => <BigSmall big={props.text} small={props.description} />,
			},
			{
				text: "My unstyled button",
				noStyling: true,
				onClick: () => console.log("clicked"),
			},
			{
				text: "My custom link",
				href: "https://www.google.com",
				openNew: true,
				render: (props) => (
					<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
						<InlineText style={{ color: "var(--color--blue)" }}>{props.text}</InlineText>
						<Icon>
							<IconOpenNew />
						</Icon>
					</div>
				),
			},
			{
				text: "My unstyled link",
				noStyling: true,
				href: "https://www.google.com",
				openNew: true,
			},
			{
				text: "My custom content",
				disabled: true,
				render(props) {
					return (
						<div>
							<p>{props.text}</p>
						</div>
					);
				},
			},
		]}
	/>
);

const CustomRendererUsage = () => {
	const items = [
		{
			text: "First link",
			href: "https://www.google.com",
			count: 1,
		},
		{
			text: "Second link",
			href: "https://www.google.com",
			count: 2,
		},
	];

	return (
		<ActionList
			items={items}
			itemRenderer={(props) => {
				const item = props.item as LinkItem & { count: number };
				return (
					<LinkContainer
						tabIndex={-1}
						href={item.href}
						style={{
							width: "100%",
							padding: "10px",
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<LinkContainer.LinkText>{item.text}</LinkContainer.LinkText>
						<Badge>{item.count}</Badge>
					</LinkContainer>
				);
			}}
		/>
	);
};

const getItems = () => {
	const items = [];
	for (let i = 0; i < 20; i++) {
		items.push({
			text: `Item ${i + 1}`,
			onClick: () => console.log(`clicked item ${i + 1}`),
			selected: false,
		});
	}
	return items;
};

const SelectedUsage = () => {
	const items = getItems();
	items[15].selected = true;

	return (
		<div style={{ height: "250px" }}>
			<ActionList items={items} />
		</div>
	);
};

const SimplePaginationUsage = () => {
	return <ActionList items={getItems()} pageChanger={{}} />;
};

const SelectedPaginationUsage = () => {
	const items = getItems();
	items[8].selected = true;

	return <ActionList items={items} pageChanger={{}} />;
};

const UpdateItemsPaginationUsage = () => {
	const [items, setItems] = useState(getItems());

	return (
		<>
			<Button
				onClick={() => {
					const selectedItem = Math.floor(Math.random() * items.length);
					setItems(
						items.map((item, i) => ({
							...item,
							selected: i === selectedItem,
							text: `Updated ${item.text}`,
						}))
					);
				}}
			>
				Update items
			</Button>
			<ActionList items={items} pageChanger={{}} />
		</>
	);
};

const ExternalControlPaginationUsage = () => {
	const [page, setPage] = useState(2);

	return (
		<>
			<Button disabled={page === 1} onClick={() => setPage(1)}>
				Go to page 1
			</Button>
			<Button disabled={page === 2} onClick={() => setPage(2)}>
				Go to page 2
			</Button>
			<Button disabled={page === 3} onClick={() => setPage(3)}>
				Go to page 3
			</Button>
			<Button disabled={page === 4} onClick={() => setPage(4)}>
				Go to page 4
			</Button>
			<ActionList
				items={getItems()}
				pageChanger={{ page, onPageChange: (page) => console.log(`changed to page ${page}`) }}
			/>
		</>
	);
};
